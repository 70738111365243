export default function labels() {
    // Activate labels if fields already have a value
    $('.form-field input').each((index, el) => {
        const inputVal = $(el).val();
        // const required = $(el).attr(required);
        // const thisEl = el.hasAttribute('required');

        if (inputVal) {
            $(el).parent('.form-field').addClass('active');
        }

        // console.log(thisEl);

        if ($(el).attr('required')) {
            $(el).parent('.form-field').addClass('required');
        }
    });


    // Add class to form fields to animate labels
    $('textarea, input[type="text"], input[type="email"], input[type="number"], input[type="url"], input[type="date"]').on('focus', (e) => {
        console.log('active');
        $(e.currentTarget).parent('.form-field').addClass('active');
    });

    $('textarea, input[type="text"], input[type="email"], input[type="number"], input[type="url"], input[type="date"]').on('blur', (e) => {
        if (!$(e.currentTarget).val()) {
            $(e.currentTarget).parent('.form-field').removeClass('active');
        }
    });
};
