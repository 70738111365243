export default function selectShowHide() {
  // Activate labels if fields already have a value

  $('select').change(function () {

    const selected = $(':selected', $(this)).data('option');
    const match = $(`.content[data-select-index=${selected}]`);

    $('.content-wrapper .content').hide();
    $(match).show();

  });
};



const receiveOptions = () => {
  const digitalOnly = $('.do-container');
  const digitalAndPrint = $('.dap-container');
  const radio = $('input[name="receive-guide-option"]');

  $(radio).on('click', () => {
    var radioValue = $("input[name='receive-guide-option']:checked").val();
    if (radioValue === 'digital_only') {
      // function for digital only, show the right container
      $('.show-hide-container').removeClass('active');
      $(digitalOnly).addClass('active');
      $(digitalAndPrint).find('input').prop("disabled", true);
    } else {
      $(digitalAndPrint).find('input').prop("disabled", false);
      $('.show-hide-container').removeClass('active');
      $(digitalAndPrint).addClass('active');
    }
  });

};

// get-guide ajax submit
const handleGuideSubmit = (event) => {
  event.preventDefault();

  const myForm = event.target;
  const formData = new FormData(myForm);

  // let object = {};
  // formData.forEach((value, key) => object[key] = value);
  // let jsonData = JSON.stringify(object);

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  }).then((response) => response.text())
    .then((rb) => {
      let thankYouMessage = '<p class="mt-3">Thank you for requesting your copy of Navigating Pulmonary Hypertension: A Guide for Newly Diagnosed Patients. You can begin your journey now by clicking the button below.</p><p class="print-message">If you have selected to also receive a print copy, this will arrive in 4 – 6 weeks.</p><a class="btn btn-primary download mt-3" href="/downloads/PHA_NavigatingPH_Brochure.pdf" target="_blank">view and download the navigating ph guide</a>';

      const guideFormContainer = document.getElementById("get-guide-container");
      const guideFormThanks = document.getElementById("get-guide-thank-you");

      guideFormThanks.innerHTML = thankYouMessage;
      guideFormContainer.style.display = 'none';
      guideFormThanks.style.cssText = 'display: flex !important';

      if ($('.dap-container').hasClass('active')) {
        $('.print-message').css('display', 'block');
      }

      console.log('guide form submitted successfully');

    })
    .catch((error) => alert(error));
};


// contact ajax submit
const handleContactSubmit = (event) => {
  event.preventDefault();

  const myForm = event.target;
  const formData = new FormData(myForm);

  
  let thankYouMessage = '<p>Thank you for contacting the Pulmonary Hypertension Association. We have received your question and will respond within two working days.</p>';
  
  const contactChoices = formData.get('contactChoices');
  switch (contactChoices) {
    case '1':
      thankYouMessage = '<p>Thank you for contacting the Pulmonary Hypertension Association. We will make the necessary changes and respond to you within two working days.</p>';
      break;
    case '5':
      thankYouMessage = '<p>Thank you for contacting the Pulmonary Hypertension Association. We appreciate your patience while we work to provide you with an answer within two business days.</p><p>In the meantime, find <a href="https://phassociation.org/join/">information</a> about becoming a PHA member, renewing your membership or learning about benefits.</p><p>Renewing? There are three easy ways to renew your PHA membership:</p><ul><li>Fast, secure <a href="https://phassociation.org/medicalmembershipnetworks/phcr/join-renew/">online payment.</a></li><li>Mail a check to PHA at 8401 Colesville Road, Suite 200, Silver Spring, MD 20910. Please note membership on the memo line.</li><li>Call member services at 301-565-3004 x833. </li></ul><p>Thank you for becoming a PHA member.</p>';
      break;
    case '6':
      thankYouMessage = '<p>Thank you for contacting the Pulmonary Hypertension Association. We have received your question and will respond within two working days.</p><p>Until then, please visit our <a href="https://phassociation.org/patients/insurance-and-treatment-access/financialassist/">Financial Assistance page</a> or contact your PH specialist.</p>'
      break;
    case '9':
      thankYouMessage = 'Thank you for contacting PHA. We appreciate your interest in volunteering and will respond shortly.';
      break;
  }

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  }).then((response) => response.text())
    .then((rb) => {
      // let thankYouMessage = rb;
      
      const contactFormContainer = document.getElementById("contact-Container");
      const contactFormThanks = document.getElementById("contact-thank-you");

      contactFormThanks.innerHTML = thankYouMessage;
      contactFormContainer.style.display = 'none';
      contactFormThanks.style.cssText = 'display: flex !important';

      console.log('contact form submitted successfully');

    })
    .catch((error) => alert(error));
};

document.addEventListener("DOMContentLoaded", () => {
  //contact
  const contactForm = document.getElementById("contact");
  contactForm.addEventListener("submit", handleContactSubmit);

  //get-the-guide
  const getGuideForm = document.getElementById("get-guide");
  getGuideForm.addEventListener("submit", handleGuideSubmit);

  // radio button choose your own adventure:
  receiveOptions();
});
