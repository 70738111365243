import 'scripts/main.js';
import modals from 'scripts/modals.js'
import labels from 'scripts/labels.js'
import selectShowHide from 'scripts/forms.js'

document.addEventListener('DOMContentLoaded', () => {
  // do your setup here
  console.log('Initialized app');

  modals();
  labels();
  selectShowHide();


});
