
import { gsap } from 'gsap';

export default function modals() {
    const modalTrigger = $('.modal-trigger');

    $('.js-close').on('click', () => {
        if ($('body').hasClass('modal-active')) {
            $('body').removeClass('modal-active');
            gsap.to('.modal', { duration: 0.5, visibility: 'hidden', opacity: 0 });
        }
    });

    $(modalTrigger).on('click', (e) => {
        const thisTrigger = e.currentTarget;
        const thisData = $(thisTrigger).attr('data-modal');
        const modalMatch = $(`.modal#${thisData}`);
        const modalContent = $(modalMatch).find('.modal-content');

        const animateModalTimeline = gsap.timeline();
        gsap.set('.modal', { opacity: 0, visibility: 'hidden' });
        if (!$('body').hasClass('modal-active')) {
            $('body').addClass('modal-active');
            gsap.to(modalMatch, { duration: 0.5, visibility: 'visible', opacity: 1 });
            gsap.set(modalContent, {
                translateY: '100px',
            });

            animateModalTimeline.to(modalContent, {

                translateY: 0,
            });
        }
    });

}


